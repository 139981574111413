<script setup lang="ts">
import { IconExclamationCircle, IconLanguage, IconMail, IconSettings, IconLogout2, IconBrightness, IconToggleLeftFilled, IconExclamationCircleFilled } from '@tabler/icons-vue';
const customizer = useCustomizerStore();
import { useUsersStore } from '@/stores/users';
import { useAuthStore  } from '@/stores/auth';
// import { profileDD } from "@/_mockApis/headerData";
import { languageDD } from '@/_mockApis/headerData';
import icon1 from '/images/svgs/icon-account.svg';
import icon2 from '/images/svgs/icon-dd-invoice.svg';
import icon3 from '/images/svgs/icon-languages.svg';
import icon4 from '/images/svgs/icon-dd-bulb.svg';
import icon5 from '/images/svgs/icon-dd-mobile.svg';
import icon6 from '/images/svgs/icon-dd-lifebuoy.svg';

import { gitInfo } from "~/data/gitInfo";
const authStore = useAuthStore();
const userStore = useUsersStore();
const store = useStoresStore();

const profileDD = [
    {
        avatar: icon1,
        title: 'My Profile',
        subtitle: 'Account settings',
        href: '/user/myprofile',
        showWarning: !authStore.isThirdpartyFilled,
        visible: true
    },
    {
        avatar: icon5,
        title: 'My Cards',
        subtitle: 'Manage payment methods',
        href: '/user/myCreditCards',
        showWarning: false,
        visible: userStore.hasPaymentMethod,
    },
    {
        avatar: icon2,
        title: 'My Purchases',
        subtitle: 'Previous purchases',
        href: '/user/myPurchases',
        showWarning: false, // TODO: Cantidad de compras previas
        visible: true
    }
];
</script>

<template>
  <!-- ---------------------------------------------- -->
  <!-- notifications DD -->
  <!-- ---------------------------------------------- -->
  <v-menu :close-on-content-click="true">
    <template v-slot:activator="{ props }">
      <v-btn variant="text" v-bind="props" icon >
        <v-avatar size="40" class="border border-2 border-grey200" >
          <img v-if="authStore?.userSession?.user?.user_info?.picture" 
            :src="`${authStore?.userSession?.user?.user_info?.picture}`" 
            onerror="this.src='/images/profile/user-1.jpg?=0';"
            width="40"
           />
          <img v-else src="/images/profile/user-1.jpg?=2" width="40" />
        </v-avatar>
        <slot>
        </slot>
      </v-btn>
    </template>
    <v-sheet rounded="md" width="340" elevation="6" class="mt-2">
      <div class="px-4 pt-2">
        <div class="d-flex align-center mt-4 pb-4">
          <v-avatar size="60">
            <img v-if="authStore?.userSession?.user?.user_info?.picture" 
              :src="authStore?.userSession?.user?.user_info?.picture" 
              onerror="this.src='/images/profile/user-1.jpg';" :width="60" />
            <img v-else src="/images/profile/user-1.jpg" :width="60" />
          </v-avatar>
          <div class="ml-2">
            {{ $t('index.hello') }} <span v-if="authStore?.userSession?.user?.thirdparty?.first_name" class="text-h6 text-capitalize">{{ `${authStore?.userSession?.user?.thirdparty?.first_name.toLowerCase()} ${authStore?.userSession?.user?.thirdparty?.middle_name.toLowerCase()}`.trim() }}!</span>
            <!-- <span class="text-subtitle-1 font-weight-regular textSecondary"
              >Designer</span
            > -->
            <div class="d-flex align-center mt-1">
              <IconMail size="18" stroke-width="1.5" />
              <span class="text-subtitle-1 font-weight-regular textSecondary ml-2">{{
                authStore?.userSession?.user?.email }}
              </span>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
      <perfect-scrollbar class="pt-2" style="height: calc(100vh - 80px)" 
        :style="`max-height: ${(profileDD.filter(x => x.visible).length * 60) + 150}px`" >
        <v-list class="py-0 theme-list" lines="two">
          <v-list-item 
            v-for="item in profileDD" :key="item.title" class="py-2 pl-4 custom-text-primary"
            :to="item.href"
            v-show="item.visible"
            >
            <template v-slot:prepend>
              <v-avatar
                size="48"
                color="lightprimary"
                rounded="md"
              >
                <v-img
                  :src="item.avatar"
                  width="30"
                  height="30"
                  :alt="item.avatar"
                  :cover="false"
                />
              </v-avatar>
            </template>
            <div>
              <h6 class="text-subtitle-1 font-weight-bold custom-title d-flex align-items-center mb-0">
                <!-- <IconSettings size="14" class="mr-1" /> -->
                 {{ $t(item.title) }}  
                <IconExclamationCircleFilled v-if="item.showWarning" size="18" class="ml-1 text-error" />
              </h6>
            </div>
            <p class="text-subtitle-1 font-weight-regular textSecondary">
              {{ $t(item.subtitle) }}
            </p>
          </v-list-item>
          <v-list-item class="py-2 pl-4 custom-text-primary">
            <template v-slot:prepend>
              <v-avatar
                size="48"
                color="lightprimary"
                rounded="md"
              >
                <v-img
                  :src="icon3"
                  width="30"
                  height="30"
                  :cover="false"
                  :alt="icon3"
                />
              </v-avatar>
            </template>
            <div>
              <h6 class="text-subtitle-1 font-weight-bold d-flex align-items-center mb-0">
                <!-- <IconLanguage size="14" class="mr-1" />  -->
                {{ $t('language') }}
              </h6>
            </div>
            <p class="text-subtitle-1 font-weight-regular">
              <v-btn
                  v-for="(item, index) in languageDD"
                  :key="index"
                  variant="text"
                  :class="{ 'd-none': $i18n.locale == item.value }"
                  class="my-0 mx-0 px-0 py-0"
                  style="margin-top: -10px !important; margin-bottom: 0px !important;"
                  @click="() => { $i18n.locale = item.value; userStore.setLanguage(item.value) }"
              >
              {{ $t('language_change') }} <img :src="item.avatar" :alt="item.avatar" width="18" height="18" class="obj-cover rounded ml-2" />
              </v-btn>
            </p>
          </v-list-item>
          <v-list-item class="py-2 pl-4 custom-text-primary" @click="() => { authStore.setToShowTutorial(Object.keys(store.getSelectedStore).length === 0 ? 1 : 2); navigateTo('/'); }">
            <template v-slot:prepend>
              <v-avatar
                size="48"
                color="lightprimary"
                rounded="md"
              >
                <v-img
                  :src="icon6"
                  width="30"
                  height="30"
                  :cover="false"
                  :alt="icon6"
                />
              </v-avatar>
            </template>
            <div>
              <h6 class="text-subtitle-1 font-weight-bold d-flex align-items-center mb-0">
                {{ $t('basic.help') }}
              </h6>
            </div>
            <p class="text-subtitle-1 font-weight-regular textSecondary">
              {{ $t('basic.help-text') }}
            </p>
          </v-list-item>
          <v-list-item v-if="'TODO' === 'FaltaMejorarTodavíaNo'" 
            class="py-2 pl-4 custom-text-primary" @click="customizer.SET_THEME(customizer.actTheme === 'ORANGE_THEME' ? 'DARK_ORANGE_THEME' : 'ORANGE_THEME')">
            <template v-slot:prepend>
              <v-avatar
                size="48"
                color="lightprimary"
                rounded="md"
              >
                <v-img
                  :src="icon4"
                  width="30"
                  height="30"
                  :cover="false"
                  :alt="icon4"
                />
              </v-avatar>
            </template>
            <div>
              <h6 class="text-subtitle-1 font-weight-bold custom-title d-flex align-items-center mb-0">
                <IconBrightness size="14" class="mr-1" /> {{ $t('theme') }}
              </h6>
            </div>
            <p class="text-subtitle-1 font-weight-regular textSecondary">
              {{ $t('theme_change') }}
              <v-btn
                icon
                size="x-small" variant="flat"
                style="margin: -5px 0px"
              >
                <IconToggleLeftFilled size="18" />
              </v-btn>
            </p>
          </v-list-item>
        </v-list>
        
      </perfect-scrollbar>
      <!-- <div class="px-8 py-3">
        <div
          class="bg-lightprimary rounded-md pa-5 overflow-hidden position-relative"
        >
          <h5 class="text-h6">
            Unlimited<br />
            Access
          </h5>
          <v-btn variant="flat" color="primary" class="mt-3">Upgrade</v-btn>
          <img
            src="/images/backgrounds/unlimited-bg.png"
            alt="bg-img"
            class="right-pos-img"
          />
        </div>
      </div> -->
      <div class="pt-4 pb-4 px-8 text-center">
        <v-btn color="secondary" size="large" rounded="xl" block @click="navigateTo('/logout')">
          <IconLogout2 size="22" class="mr-2" />  {{ $t('Logout') }}
        </v-btn>
      </div>
      <p class="text-center text-grey600 text-xs py-2">{{ gitInfo }}</p>
    </v-sheet>
  </v-menu>
</template>
